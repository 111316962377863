import React, { useEffect, useState, useRef } from 'react'
import DatePicker from "react-datepicker"
import TimePicker from "../../components/sections/time-picker"
import { Link } from 'gatsby'
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css"
import './style.css'
import { useMutation } from 'urql'
import { SearchProvider } from '../../context/search-provider'
import { Seo } from "../../components/seo/default"
import { Layout } from "../../components/layout/layout"
import { STRINGS } from "../../constants"

const CreateCheckout = `
mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
const bookingVariantId = process.env.GATSBY_BOOKING_VARIANT_ID
const shopifyStoreUrl = process.env.GATSBY_SHOPIFY_STORE_URL
const shopUrl = process.env.GATSBY_SHOP_URL
const backendUrl = process.env.GATSBY_BACKEND_URL

function BookPage() {
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [agreeTos, setAgreeTos] = useState(false)
    const [availableTimes, setAvailableTimes] = useState([])
    const [disabledDates, setDisabledDates] = useState({
        days: [0],
        dates: []
    })
    const [selectedDate, setSelectedDate] = useState()
    const [selectedTime, setSelectedTime] = useState()
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [selectedDateTimeToShow, setSelectedDateTimeToShow] = useState()
    const availabilitiesTemp = useRef()

    const [createCheckoutResult, createCheckout] = useMutation(CreateCheckout)

    const filterDate = (date) => {
        const day = moment(date).weekday()
        if (disabledDates.dates.includes(moment(date).format('YYYY-MM-DD'))) return false

        const availability = availabilitiesTemp?.current?.find(val => val.date === moment(date).format('YYYY-MM-DD'))

        if (availability?.available && availability?.spots.length > 0) return true

        return !disabledDates.days.includes(day)
    }

    const setAvailabilities = (val) => {
        availabilitiesTemp.current = val
    }

    const dateSelectHandler = (date) => {
        setSelectedDate(date)
        setAvailableTimes([])
        availabilitiesTemp.current.forEach((val) => {
            if(val.date === moment(date).format('YYYY-MM-DD') && val.available) {
                setAvailableTimes(val.spots)
            }
        });

        if(selectedTime) {
            const selectedDaySpots = availabilitiesTemp.current.find(val => val.date === moment(date).format('YYYY-MM-DD'))?.spots
            const selectedTimeIsAvailable = selectedDaySpots.find(val => val.start_time === selectedTime)
            if (selectedTimeIsAvailable) {
                const newSelectetDateTime = moment(`${moment(date).format('YYYY-MM-DD')} ${selectedTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');
                setSelectedDateTime(newSelectetDateTime)
    
                const bookTime = moment(newSelectetDateTime, 'YYYY-MM-DD hh:mm A').format('MMMM Do h:mm')
                const ap = moment(newSelectetDateTime, 'YYYY-MM-DD hh:mm A').format('A')
                const myBookingTime = bookTime.split(':')[1] === '00' ? bookTime.split(':')[0] + ap : bookTime + ap
                setSelectedDateTimeToShow(myBookingTime)
            }
            else {
                setSelectedTime(null)
                setSelectedDateTime(null)
                setSelectedDateTimeToShow(null)
            }
        }
    }

    const selectTimeHandler = (val) => {
        setSelectedTime(val)
        const newSelectetDateTime = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${val}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A')
        setSelectedDateTime(newSelectetDateTime)

        const bookTime = moment(newSelectetDateTime, 'YYYY-MM-DD hh:mm A').format('MMMM Do h:mm')
        const ap = moment(newSelectetDateTime, 'YYYY-MM-DD hh:mm A').format('A')
        const myBookingTime = bookTime.split(':')[1] === '00' ? bookTime.split(':')[0] + ap : bookTime + ap
        setSelectedDateTimeToShow(myBookingTime)
    }

    const checkout = async () => {
        try {
            setIsLoading(true)
            const lineItems = [{ 
                customAttributes: [{
                  key: 'Date Time',
                  value: selectedDateTime
                }],
                quantity: 1,
                variantId: window.btoa(`gid://shopify/ProductVariant/${bookingVariantId}`)
            }];
        
            const checkoutInput = { lineItems, note: 'appointment_from_headless_store' }
            const variables = { input: checkoutInput }
            const { data: { checkoutCreate } } = await createCheckout(variables)
            if (checkoutCreate.checkoutUserErrors.length) throw new Error(checkoutCreate.checkoutUserErrors[ 0 ].message)
            const checkourUrl = checkoutCreate.checkout.webUrl.replace(`https://${shopifyStoreUrl}`, shopUrl);
            window.location = checkourUrl
        }
        catch (err) {
            console.log('[err]')
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const url = `${backendUrl}/.netlify/functions/availability`
                const res = await fetch(url)
                const data = await res.json()
                setAvailabilities(data.days)
        
                const todayData = data.days[0];
                let disabledDatesTemp = []
        
                if(todayData.spots.length === 0) {
                    disabledDatesTemp.push(moment(todayData.date, 'YYYY-MM-DD'))
                }

                if (data.disabledDates.length > 0) disabledDatesTemp = disabledDatesTemp.concat(data.disabledDates)
        
                setDisabledDates({
                    days: data.disabled,
                    dates: disabledDatesTemp
                })
                setLoading(false)
                dateSelectHandler(moment().toDate())
            }
            catch (err) {
                setLoading(false)
                console.log('[err]', err)
            }
        }

        init()
    }, [])

    return (
        <Layout>
            <Seo title={`Piercing appointment`} />
            <div className="w-full bg-red-primary text-white mx-auto flex flex-col sm:flex-col items-center justify-center py-3 px-4 z-10">
                <div className="w-full text-center">
                    <h1 className="leading-tight text-xl uppercase font-bold">Chesterfield Studio</h1>
                    <p className="uppercase font-thin">Piercing Appointment</p>
                </div>
            </div>
            <div className="contact-body flex flex-col items-center justify-center mb-0 w-full mx-auto py-0 px-0 max-w-5xl" >
                <div className="w-full picker-wrapper bg-gray-sm text-black relative">
                    {
                        loading && (
                            <div className="absolute bg-background-overlay left-0 top-0 w-full h-full flex items-center justify-center z-50">
                                <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-gray-sm border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                            </div>
                        )
                    }

                    <DatePicker
                        inline
                        filterDate={filterDate}
                        excludeDateIntervals={[{start: moment().subtract(9999, 'days').toDate(), end: moment().subtract(1, 'days').toDate() }]}
                        focusSelectedMonth
                        onSelect={dateSelectHandler}
                        useWeekdaysShort
                        calendarStartDay={1}
                        selected={selectedDate ? selectedDate : moment().toDate()}
                    />

                    { selectedDate && <TimePicker times={availableTimes} selectedTime={selectedTime} selectTime={selectTimeHandler} /> }

                    {
                        selectedTime && (
                            <div className="action-wrapper pb-4 text-center fixed bottom-0 z-10 w-full bg-primary left-0 cursor-pointer">
                                <div className="bg-red-primary mb-4 p-2 text-white">
                                    <p className="text-lg uppercase">My Booking: {selectedDateTimeToShow}</p>
                                </div>
                                <div className="px-4">
                                    {
                                        agreeTos ? (
                                            <button
                                                className="w-full h-14 button text-center flex items-center justify-center bg-primary rounded-full border border-white py-2 px-8 text-white uppercase text-xl"
                                                onClick={checkout}
                                            >
                                                {
                                                    createCheckoutResult.fetching || isLoading ? (
                                                        <span>
                                                            <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-gray-sm border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                                                        </span>
                                                    )
                                                    : (
                                                        <span>{STRINGS.BOOKING_BUTTON_TEXT}</span>
                                                    )
                                                }
                                            </button>
                                        ) : (
                                            <button
                                                className="w-full h-14 button text-center flex flex-col items-center justify-center bg-primary rounded-full border border-white py-2 px-2 text-white uppercase text-sm sm:text-xl"
                                                onClick={() => setAgreeTos(true)}
                                            >
                                                <span className='flex items-center justify-center'>
                                                    <span className='flex items-center justify-center'>
                                                        <input
                                                            type="checkbox"
                                                            value={agreeTos}
                                                            className="w-4 h-4 sm:w-5 sm:h-5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                            onChange={(e) => setAgreeTos(e.target.value)}
                                                        />
                                                        <span className='ml-2'>I agree to &nbsp;</span>
                                                    </span>
                                                    <span>
                                                        <Link to="/booking-terms-conditions" className="underline">
                                                            terms & conditions
                                                        </Link>
                                                    </span>
                                                </span>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </Layout>
    )
}

export default function BookPageTemplate(props) {
    return (
        <SearchProvider>
            <BookPage {...props} />
        </SearchProvider>
    )
}
